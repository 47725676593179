<template>
  <div>
   
    <vx-card>
      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
            <div
              class="flex flex-wrap-reverse items-center data-list-btn-container"
            ></div>
            <v-select
              v-model="dataTableParams.limit"
              class="mr-4"
              :options="limitOptions"
              :clearable="false"
            />
          </div>

          <template slot="thead">
            <vs-th sort-key="title">Page Title</vs-th>
            <vs-th sort-key="createdAt">Created At</vs-th>
            <vs-th sort-key="updatedAt">Updated At</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].title">{{
                data[indextr].title
              }}</vs-td>
              <vs-td :data="data[indextr].createdAt">{{
                moment(data[indextr].createdAt).format("DD/MM/YYYY")
              }}</vs-td>
              <vs-td :data="data[indextr].updatedAt">{{
                moment(data[indextr].updatedAt).format("DD/MM/YYYY")
              }}</vs-td>
              <vs-td :data="data[indextr]._id">
                <vs-row vs-type="flex">
                  <vx-tooltip text="Edit" position="top" delay=".3s">
                    <feather-icon
                      icon="Edit3Icon"
                      svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer"
                      @click="editDetailHandler(data[indextr]._id)"
                    />
                  </vx-tooltip>
                </vs-row>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <span class="mr-2"
          >{{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}</span
        >
        <vs-pagination
          :total="totalPage"
          v-model="dataTableParams.page"
        ></vs-pagination>
      </div>
    </vx-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      isMounted: false,
      totalDocs: 0,
      currentPage: 1,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 30,
      },
      users: [],
      limitOptions: [30, 50, 100, 150],
    };
  },
  methods: {
    ...mapActions("page", [
      "fetchPages"
    ]),
    moment(date) {
      return moment(date);
    },
    
    getPagesList() {
      let self = this;
      this.fetchPages(self.dataTableParams).then((res) => {
        self.users = res.data.data.docs;
        self.totalDocs = res.data.data.pagination.total;
        self.page = res.data.data.pagination.page;
      });
    },
    handleSearch(searching) {
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPagesList();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getPagesList();
    },
    handleSort(key, active) {
      
      if(key) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPagesList();
      }
    },
    editDetailHandler(id) {
      this.$router.push({ name: "CMSPageDetails", params: { id: id } });
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getPagesList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getPagesList();
      }
    },
  },
  created() {
    this.getPagesList();
  },
};
</script>
